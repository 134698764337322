/**
 * Created by Dev team on 19/11/2024.
 */

(function btTradeViewReportsCtrlClosure() {
  'use strict';

  angular.module('dashboard').controller('btTradeViewReportsCtrl', btTradeViewReportsCtrl);

  btTradeViewReportsCtrl.$inject = [
    '$scope',
    'btLinkDataService',
    '$ionicLoading',
    'btToastrService',
    'btShareScopeService',
  ];

  /**
   * Controller for trade view report page.
   *
   * @ngdoc controller
   * @name ecapp.btReportCtrl
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {ecapp.ILinkDataService} btLinkDataService - ?
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IShareScopeService} btShareScopeService
   */
  function btTradeViewReportsCtrl($scope, btLinkDataService, $ionicLoading, btToastrService, btShareScopeService) {
    console.log('Running btTradeViewReportsCtrlClosure');

    $scope.loading = false;
    const day = moment().day();
    const dateFormat = 'MM/DD/YYYY';
    const weekDifference = [0, 1, 2, 6].indexOf(day) > -1 ? 1 : 0; // If day is Sunday, Monday, Tuesday or Saturday, then subtract 1 week
    $scope.reportDate = {
      date: moment().subtract(weekDifference, 'weeks').startOf('week').add(5, 'days').format(dateFormat),
      maxDate: moment().add(day !== 5 ? 7 : 0, 'day').format(dateFormat),
      disabledWeekdays: [0, 1, 2, 3, 4, 6], // Disable all days except Friday
    };

    $scope.hasTradeViewReports = false;
    $scope.reports = null;
    $scope.deliveryMethodOptions = [
      {
        id: 'portal-download',
        label: 'Portal Download',
        selected: true,
        disabled: true,
      },
      {
        id: 'email',
        label: 'Email',
        selected: false,
        disabled: false,
      },
      {
        id: 'api-delivery',
        label: 'API Delivery (Enterprise Subscription Required)',
        selected: false,
        disabled: false,
      },
    ];
    $scope.viewFile = viewFile;
    $scope.downloadFile = downloadFile;
    $scope.contactUs = contactUs;
    $scope.onDateChanged = onDateChanged;

    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * Activate controller
     */
    function activate() {
      btShareScopeService
        .wait()
        .then(function () {
          $scope.hasTradeViewReports = btShareScopeService.getAccountInfoField('tradeViewReports');
        })
        .then(function () {
          if ($scope.hasTradeViewReports) {
            getReports();
          }
        });
    }

    /**
     * Get reports
     */
    function getReports() {
      $scope.loading = true;
      $scope.reports = [];
      btLinkDataService
        .getReports(moment($scope.reportDate.date).format('YYYY-MM-DD'), btLinkDataService.reportTypes.TRADE_VIEW)
        .then(function (response) {
          if (response.reports && typeof response.reports === 'object' && Object.keys(response.reports).length > 0) {
            $scope.reports = Object.keys(response.reports).map(function (type) {
              const files = response.reports[type].map(function (file) {
                return {
                  name: file,
                  isPdf: file.toLowerCase().endsWith('.pdf'),
                };
              });

              return { type, files };
            });
          }
          $scope.loading = false;
        })
        .catch(function () {
          $scope.loading = false;
        });
    }

    /**
     * View file
     * @param {string} type - report type
     * @param {string} name - report name
     */
    function viewFile(type, name) {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Fetching report link...</p>' });
      btLinkDataService
        .getSignedUrlOfReport(
          moment($scope.reportDate.date).format('YYYY-MM-DD'),
          type,
          name,
          btLinkDataService.reportTypes.TRADE_VIEW
        )
        .then(function (response) {
          if (response && response.url) {
            window.open(response.url, '_blank');
          }
          $ionicLoading.hide();
        })
        .catch(function () {
          $ionicLoading.hide();
          btToastrService.error('Failed to open file', 'Reports');
        });
    }

    /**
     * Download file
     * @param {string} type - report type
     * @param {string} name - report name
     */
    function downloadFile(type, name) {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Fetching report file...</p>' });
      btLinkDataService
        .getFileContentOfReport(
          moment($scope.reportDate.date).format('YYYY-MM-DD'),
          type,
          name,
          btLinkDataService.reportTypes.TRADE_VIEW
        )
        .then(function (response) {
          if (response && response.data) {
            const downloadLink = document.createElement('a');
            downloadLink.href = response.data;
            downloadLink.download = name;
            downloadLink.click();
          }
          $ionicLoading.hide();
        })
        .catch(function () {
          $ionicLoading.hide();
          btToastrService.error('Failed to download file', 'Reports');
        });
    }

    /**
     * React on contact us
     * @param {Event} $event - event
     */
    function contactUs($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Submitting request access...</p>' });
      btLinkDataService
        .requestAccessForEodReport(
          $scope.deliveryMethodOptions.filter((d) => d.selected).map((d) => d.label),
          btLinkDataService.reportTypes.TRADE_VIEW
        )
        .then(function () {
          $ionicLoading.hide();
          btToastrService.success('Request access of tradeview reports submitted successfully', 'TradeView Reports');
        })
        .catch(function () {
          $ionicLoading.hide();
          btToastrService.error('Failed to submit request access of tradeview reports', 'TradeView Reports');
        });
    }

    /**
     * React on date changed
     */
    function onDateChanged() {
      getReports();
    }

    /**
     * React on scope destroy
     */
    function onDestroy() {
      console.log('Destroy btTradeViewReportsCtrl');
    }
  }
})();
